import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PortableText from '@sanity/block-content-to-react';
import SEO from '../components/SEO';
import Breadcrumbs from '../components/Breadcrumbs';
import { PageGrid } from '../styles/PageStyles';
import ContactForm from '../components/ContactForm';

export default function ContactPage({ data: { page } }) {
  return (
    <>
      <SEO
        title={page.title}
        image={page.image?.asset?.fluid?.src}
        description="Contact Emerald Heating at 07538008378 to discuss your next project."
      />
      <Breadcrumbs currentPage={page} />
      <PageGrid>
        {page.image?.asset?.fluid && (
          <Img fluid={page.image?.asset?.fluid} alt={page.title} />
        )}
        <div>
          <h1>{page.title}</h1>

          {page._rawContent && <PortableText blocks={page._rawContent} />}
          <ContactForm />
        </div>
      </PageGrid>
    </>
  );
}

export const query = graphql`
  query($slug: String!) {
    page: sanityPage(slug: { current: { eq: $slug } }) {
      title
      id
      slug {
        current
      }
      _rawContent(resolveReferences: { maxDepth: 10 })
      image {
        asset {
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;
