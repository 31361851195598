import * as React from 'react';
import styled from 'styled-components';
import UseForm from '../utils/useForm';
import useRequestACallback from '../utils/useRequestACallback';

const MessageStyles = styled.div`
  width: 100%;
  flex-shrink: 0;
  max-width: 800px;

  p {
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
    border-width: 1px;
    border-color: var(--grey);
    border-style: solid;
    border-radius: 8px;
    overflow: hidden;
    padding: 30px;
  }
`;

const RequestCallbackStyles = styled.form`
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
  border-width: 1px;
  border-color: var(--grey);
  border-style: solid;
  border-radius: 8px;
  overflow: hidden;
  padding: 30px;
  width: calc(100% - 60px);
  flex-shrink: 0;
  margin-bottom: auto;
  max-width: 800px;

  legend {
    font-size: 28px;
    color: var(--dgreen);
    font-weight: bold;
    line-height: 35px;
    margin-bottom: 15px;
  }

  fieldset {
    border: 0;
    padding: 0;
  }

  input,
  textarea {
    border-radius: 4px;
    width: calc(100% - 24px);
    padding: 12px;
    margin: 15px 0;
    border: 1px solid var(--grey);
    font-size: 16px;
    line-height: 23px;

    &:hover,
    &:focus {
      border: 1px solid var(--dgreen);
    }
  }

  .disclaimer {
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 15px;
  }

  .pot {
    display: none;
  }
`;

export default function RequestACallback() {
  const { values, updateValue } = UseForm({
    bot: '',
    name: '',
    number: '',
    email: '',
    message: '',
  });

  const { submitForm, error, loading, message } = useRequestACallback({
    values,
  });
  if (message) {
    return (
      <MessageStyles>
        <p>{message}</p>
      </MessageStyles>
    );
  }
  return (
    <>
      <RequestCallbackStyles onSubmit={submitForm}>
        <fieldset disabled={loading}>
          <label htmlFor="bot-field" className="pot">
            Don’t fill this out if you’re human:{' '}
            <input
              name="bot-field"
              id="bot-field"
              value={values.bot}
              onChange={updateValue}
            />
          </label>

          <label htmlFor="name">
            <span className="show-for-sr">Name</span>
            <input
              type="text"
              name="name"
              id="name"
              value={values.name}
              onChange={updateValue}
              placeholder="Name"
              required
            />
          </label>

          <label htmlFor="number">
            <span className="show-for-sr">Contact number</span>
            <input
              type="text"
              name="number"
              id="number"
              value={values.number}
              onChange={updateValue}
              placeholder="Contact number"
              required
            />
          </label>

          <label htmlFor="email">
            <span className="show-for-sr">Email address</span>
            <input
              type="email"
              name="email"
              id="email"
              value={values.email}
              onChange={updateValue}
              placeholder="Email address"
              required
            />
          </label>

          <label htmlFor="email">
            <span className="show-for-sr">Message</span>
            <textarea
              type="text"
              name="message"
              id="message"
              value={values.message}
              onChange={updateValue}
              placeholder="Message"
              required
            />
          </label>

          <div className="disclaimer">
            Our team will be in touch as soon as possible to discuss your needs.
          </div>

          <div>{error ? <p>Error: {error}</p> : ''}</div>

          <button type="submit" disabled={loading} className="secondary">
            Submit
          </button>
        </fieldset>
      </RequestCallbackStyles>
    </>
  );
}
